export const MESSAGE_PAGINATED_WITH_CREATOR_FRAGMENT = `
  fragment messagePaginatedWithCreatorFragment on Message {
    uid
    user {
        uid
        firstName
        lastName
        banned
        pictureFileResource {
            uid
            schemaCode
            path
        }
    }
    group {
        uid
        groupType: type
        creatorUser {
          uid
          firstName
          lastName
          banned
          pictureFileResource {
              uid
              schemaCode
              path
          }
        }
    }
    senttime
    senttimestamp
    userDeleted
    schemaCode
    type
    content
    target {
        __typename
        uid
        ... on Deal {
            name
            title
            exhibitor {
                uid
                name
            }
            displayFileResource {
                schemaCode
                path
            }
        }
        ... on Product {
            name
            exhibitor {
                uid
                name
            }
            images(first: 1) {
                fullFileResource {
                    schemaCode
                    path
                }
            }
        }
        ... on LargeProduct {
            name
            exhibitor {
                uid
                name
            }
            images(first: 1) {
                fullFileResource {
                    schemaCode
                    path
                }
            }
        }
    }
    parentMessage {
        uid
        senttime
        toUserRead
        toUserView
        userDeleted
        subject
        content
        target {
            __typename
            ... on Deal {
                uid
                name
                title
                exhibitor {
                    uid
                    name
                }
                displayFileResource {
                    schemaCode
                    path
                }
            }
            ... on Product {
                uid
                name
                exhibitor {
                    uid
                    name
                }
                images(first: 1) {
                    fullFileResource {
                        schemaCode
                        path
                    }
                }
            }
            ... on LargeProduct {
                uid
                name
                exhibitor {
                    uid
                    name
                }
                images(first: 1) {
                    fullFileResource {
                        schemaCode
                        path
                    }
                }
            }
        }
        user {
          uid
          firstName
          lastName
          jobTitle
          pictureFileResource {
              uid
              path
          }
        }
    }
    childMessages {
      uid
      senttime
      toUserRead
      toUserView
      userDeleted
      subject
      content
      user {
        uid
        firstName
        lastName
        jobTitle
        pictureFileResource {
            uid
            path
        }
      }
      target {
          __typename
          ... on Deal {
              uid
              name
              title
              exhibitor {
                  uid
                  name
              }
              displayFileResource {
                  schemaCode
                  path
              }
          }
          ... on Product {
              uid
              name
              exhibitor {
                  uid
                  name
              }
              images(first: 1) {
                  fullFileResource {
                      schemaCode
                      path
                  }
              }
          }
          ... on LargeProduct {
              uid
              name
              exhibitor {
                  uid
                  name
              }
              images(first: 1) {
                  fullFileResource {
                      schemaCode
                      path
                  }
              }
          }
      }
      parentMessage {
        uid
      }
    }
    nbReactions: _nbReactions(reaction: "LIKE")
    myReactions: _myReactions(myUid: "%authUser%" reaction: "LIKE") {
        uid
        reaction
        user {
            uid
        }
        message {
            uid
            group {
                uid
            }
        }
    }
  }
`;
